var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-investor-form-success"
  }, [_c('div', {
    staticClass: "center-modal"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "content content--top"
  }, [_c('sygni-container-title', {
    staticClass: "green"
  }, [_vm._v(" Add new product ")])], 1), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "content__ok-image"
  }, [_c('sygni-circle-status-button', {
    attrs: {
      "status": _vm.statues.active
    }
  })], 1), _c('div', {
    staticClass: "content__title title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "content__description description"
  }, [_vm._v(" You can find it on your investor's products list ")]), _c('div', {
    staticClass: "content__links"
  }, [_vm.productId ? _c('div', {
    staticClass: "content__link",
    on: {
      "click": _vm.goToProductPage
    }
  }, [_vm._v("Go to product")]) : _vm._e(), _c('div', {
    staticClass: "content__link",
    on: {
      "click": function click($event) {
        return _vm.showNewInvestorNotification();
      }
    }
  }, [_vm._v(" Go to investors list ")]), _vm.isSubscription && _vm.showAnotherInvestor ? _c('div', {
    staticClass: "content__link",
    on: {
      "click": _vm.addAnotherSubscriptionInvestor
    }
  }, [_vm._v(" Add another investor ")]) : _vm._e(), _vm.isSubscription ? _c('div', {
    staticClass: "content__link",
    on: {
      "click": _vm.goToSubscriptionsList
    }
  }, [_vm._v(" Go to subscriptions list ")]) : _vm._e()])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }